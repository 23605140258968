import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Flag`}</h1>
    <p>{`The Flag object is a simple abstraction that displays an
image or other media next to descriptive content.
The concept comes from the
`}<a parentName="p" {...{
        "href": "http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/"
      }}>{`Nicole Sullivan’s Media Object`}</a>{`
and was updated to allow vertical
alignment and renamed the
`}<a parentName="p" {...{
        "href": "https://csswizardry.com/2013/05/the-flag-object/"
      }}>{`Flag Object by Harry Roberts`}</a>{`.`}</p>
    <ComponentPreview componentName="flag--default-story" hasHTML hasReact hasAngular titleAttr="Flag Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`The Flag object is a simple concept but can
be used any time there is an image or other
media next to descriptive content.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`The media can be on the left or right of the content area.`}</li>
      <li parentName="ul">{`The content area can be vertically aligned
to the top, middle, or the bottom of the media.`}</li>
      <li parentName="ul">{`There are no restrictions on the content
that can be put in the content area.`}</li>
      <li parentName="ul">{`The Flag object can be nested inside another Flag object.`}</li>
      <li parentName="ul">{`The spacing between the media and the content
area can be set to any Spacing value.`}</li>
      <li parentName="ul">{`On small viewports, the content area
can be configured to move below the media.`}</li>
    </ul>
    <h3>{`Reverse Modifier`}</h3>
    <p>{`This modifier rearranges elements of the flag so the media is to the right of the content area.`}</p>
    <h4>{`Accessibility`}</h4>
    <p>{`Tabbing and screen readers are based on DOM order. Keep in mind that this reverse modifier is purely a visual change, not a DOM change. With more complex layouts, be mindful that visual and DOM order misatches will affect keyboard, magnifier, and screen reader users.`}</p>
    <ComponentPreview componentName="flag--reversed" hasHTML hasReact hasAngular titleAttr="Reversed Flag Example" mdxType="ComponentPreview" />
    <h3>{`Stacked Modifier`}</h3>
    <p>{`This modifier rearranges elements to stack on top of each other based on a breakpoint.
This can be used in conjunction with reverse, but the stacking will always have the media element above the body content.`}</p>
    <ComponentPreview componentName="flag--stacked" hasHTML hasReact hasAngular titleAttr="Stacked Flag Example" mdxType="ComponentPreview" />
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`A Flag object must have a fixed image or other media.`}</li>
      <li parentName="ul">{`A Flag object must have a flexible content area.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      